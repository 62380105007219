import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, setCurrentNotationIndex } from '../Store';
import styles from './NotationListStyles.module.css';
import { Notation, Bloc } from './models/Index';

interface NotationListProps {
  bloc: Bloc;
  notationRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  index: number;
}

const NotationList: React.FC<NotationListProps> = ({ bloc, notationRefs, index }) => {
  const dispatch = useDispatch();

  const currentNotationIndex = useSelector(
    (state: RootState) => state.bloc.currentNotationIndex
  );
  const mergingPoints = useSelector((state: RootState) => state.bloc.mergingPoints);

  const handleNotationClick = (time: string, notationIndex: number) => {
    const timeInSeconds = parseFloat(time);
    const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { method: 'setCurrentTime', value: timeInSeconds },
        '*'
      );
    }

    dispatch(setCurrentNotationIndex(notationIndex));
  };

  return (
    <div className={styles.notationContainer}>
      {bloc.notationArray.map((notation, notationIndex) => {
        const isMergingPoint = mergingPoints.includes(notationIndex);
        const isActive = currentNotationIndex === notationIndex;

        return (
          <div
            key={notationIndex}
            ref={(el) => (notationRefs.current[notationIndex] = el)}
            className={`${styles.notationUnit} ${isActive ? styles.active : ''} ${
              isMergingPoint ? styles.mergingPoint : ''
            }`}
            onClick={() => handleNotationClick(notation.time, notationIndex)}
          >
            <div className={styles.notationRow}>
              <strong className={styles.notationItem}>
                {notation.beat}.{notation.innerBeat}
              </strong>
            </div>
            <div className={styles.notationRow}>
              <div className={styles.notationItem}>{notation.sy}</div>
            </div>
            <div className={styles.notationRow}>
              <div className={styles.notationItem}>
                {notation.ln}
                {notation.la} {notation.lf}
              </div>
            </div>
            <div className={styles.notationRow}>
              <div className={styles.notationItem}>
                {notation.fn}
                {notation.fa} {notation.ff}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NotationList;
