import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc, getFirestore, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

interface Notation {
  backwardData: string;
  forwardData: string;
  // other notation properties...
}

interface Bloc {
  notationArray: Notation[];
  id: string;
  // other bloc properties...
}

const NotationDetailsPage: React.FC<{ blocId: string; notationIndex: number }> = ({ blocId, notationIndex }) => {
  const [matches, setMatches] = useState<Notation[]>([]);
  const [currentNotation, setCurrentNotation] = useState<Notation | null>(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchAndCompareNotations = async () => {
      try {
        // Step 1: Retrieve the current bloc and notation
        const blocDocRef = doc(db, 'blocs', blocId);
        const blocDocSnap = await getDoc(blocDocRef);
        const blocData = blocDocSnap.data() as Bloc;

        if (blocData && blocData.notationArray[notationIndex]) {
          const currentNotation = blocData.notationArray[notationIndex];
          setCurrentNotation(currentNotation);

          // Step 2: Search through all blocs for matching backwardData
          const allBlocsSnapshot = await getDocs(collection(db, 'blocs'));
          const matches: Notation[] = [];

          allBlocsSnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
            const bloc = doc.data() as Bloc;

            bloc.notationArray.forEach((notation) => {
              if (notation.backwardData === currentNotation.forwardData) {
                matches.push(notation);
              }
            });
          });

          // Step 3: Store and display the matches
          setMatches(matches);
        }
      } catch (error) {
        console.error('Error fetching and comparing notations:', error);
      }
    };

    fetchAndCompareNotations();
  }, [blocId, notationIndex, db]);

  return (
    <Box>
      <Typography variant="h5">Notation Details</Typography>
      {currentNotation && (
        <Box>
          <Typography variant="subtitle1">Current Notation</Typography>
          <Typography>Forward Data: {currentNotation.forwardData}</Typography>
          {/* Display other details of the current notation */}
        </Box>
      )}
      <Box>
        <Typography variant="h6">Matching Notations</Typography>
        {matches.length > 0 ? (
          matches.map((match, index) => (
            <Box key={index}>
              <Typography>Match {index + 1}</Typography>
              <Typography>Backward Data: {match.backwardData}</Typography>
              {/* Display other details of the matching notation */}
            </Box>
          ))
        ) : (
          <Typography>No matches found.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default NotationDetailsPage;