import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import styles from './BlocCard.module.css';
import { Bloc } from './models/Index';

interface BlocCardProps {
  bloc: Bloc;
  index: number;
  onClick: (bloc: Bloc, index: number) => void;
  className?: string;
}

const BlocCard: React.FC<BlocCardProps> = ({ bloc, index, onClick, className }) => {
  if (!bloc) return null;

  return (
    <Card
      sx={{ 
        height: '100%', 
        aspectRatio: '16/9', // Change the aspect ratio to 16:9 for a rectangular shape
        backgroundColor: '#FFE0B2', // Light peach for a friendly feel
        position: 'relative', // For overlay positioning
        boxShadow: 'none', // Remove any default box shadow to maintain transparency
        cursor: 'pointer', // Add a pointer cursor to indicate interactivity
        overflow: 'hidden', // Ensure content doesn't overflow the card
      }}
      onClick={() => onClick(bloc, index)}
      className={`${styles.card} ${className}`}
    >
      {bloc.thumbnailUrl ? (
        <CardMedia
          component="img"
          height="100%"
          image={bloc.thumbnailUrl}
          // alt="Bloc Thumbnail"
          sx={{
            opacity: 0, // Start with the image invisible
            transition: 'opacity 0.5s ease-in-out', // Smooth transition for the image
            width: '100%',
            height: 'auto',
            objectFit: 'cover', // Ensure the image covers the card area
          }}
          onLoad={(event) => {
            (event.target as HTMLImageElement).style.opacity = '1'; // Fade in the image when it loads
          }}
        />
      ) : (
      null
      )}

      <CardContent
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)', // Slightly transparent black for overlay
          color: '#FFFFFF', // White text for contrast
          textAlign: 'center',
          padding: '10px 0',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {bloc.title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BlocCard;