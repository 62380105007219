import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, TextField, Box, CircularProgress } from '@mui/material';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase-config';
import { useAuth } from '../context/AuthContext';
import styles from './StudentVideoUpload.module.css';

interface StudentVideoUploadProps {
  blocId: string;
}

const StudentVideoUpload: React.FC<StudentVideoUploadProps> = ({ blocId }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    const storageRef = ref(storage, `videos/${blocId}_${user?.uid}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => {
        console.error('Upload failed:', error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        // Update Firestore document with the downloadURL if necessary
        navigate(`/bloc/${blocId}`);
      }
    );
  };

  const handleBackClick = () => {
    navigate(`/bloc/${blocId}`);
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.uploadInstructions}>
        Upload your dance practice video to get personalized feedback from Andrés. Just focus on your movements – no need for introductions or explanations.
      </Typography>
      <Box className={styles.uploadSection}>
        <TextField
          type="file"
          onChange={handleFileChange}
          variant="outlined"
          className={styles.fileInput}
          InputProps={{
            style: {
              color: '#ffffff', // White text color
            },
          }}
        />
        <br />
        <Box className={styles.buttonGroup}>
        <Button
            variant="contained"
            onClick={handleUpload}
            sx={{
              backgroundColor: '#FF7F50',  // Bright coral color for contrast
              color: '#ffffff',             // White text for readability
              fontWeight: 'bold',           // Make the text bold for emphasis
              padding: '10px 20px',         // Adjust padding for better size
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',  // Add shadow for depth
              marginRight: '15px',          // Add margin to the right for spacing
              '&:hover': {
                backgroundColor: '#FF6347',  // Slightly darker coral on hover
              },
              '&:disabled': {
                backgroundColor: '#ccc',  // Gray out the button when disabled
                color: '#666',
              },
            }}
            disabled={!file || uploading}
          >
            {uploading ? (
              <CircularProgress size={24} sx={{ color: '#ffffff' }} />
            ) : (
              'Upload Your Video'
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={handleBackClick}
            sx={{
              color: '#FF7F50', // Match the coral color in the border
              borderColor: '#FF7F50', // Coral border color
              padding: '10px 20px',
              '&:hover': {
                borderColor: '#FF6347',  // Slightly darker coral on hover
                color: '#FF6347',
              },
            }}
          >
            Back to Lesson
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default StudentVideoUpload;
