// VideoPlayer.tsx
import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import { useSelector } from 'react-redux';
import { RootState } from '../Store'; // Adjust the import path as needed

const VideoPlayer: React.FC<{ videoUrl: string; index: number }> = ({ videoUrl, index }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const playerRef = useRef<Player | null>(null);

  const playbackSpeed = useSelector((state: RootState) => state.bloc.playbackSpeed);

  useEffect(() => {
    if (iframeRef.current) {
      playerRef.current = new Player(iframeRef.current);

      // Set the initial playback speed
      playerRef.current.setPlaybackRate(playbackSpeed).catch((error: any) => {
        console.error('Error setting playback speed:', error);
      });
    }

    return () => {
      playerRef.current?.unload();
    };
  }, []); // Empty dependency array to run once on mount

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.setPlaybackRate(playbackSpeed).catch((error: any) => {
        if (error.name === 'UnsupportedError') {
          alert('This playback speed is not supported.');
        } else {
          console.error('Error updating playback speed:', error);
        }
      });
    }
  }, [playbackSpeed]); // Update when playbackSpeed changes

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        width: '100%',
        maxWidth: '640px',
      }}
    >
      <iframe
        ref={iframeRef}
        src={`https://player.vimeo.com/video/${videoUrl}`}
        id={`vimeo-player-${index}`}
        title="Vimeo video player"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  );
};

export default VideoPlayer;
