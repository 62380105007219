import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import Player from '@vimeo/player';
import './GameComponent.css'; // Ensure this has the updated styles
import { Bloc } from './models/Index';

const GameComponent: React.FC = () => {
  const { blocId }: any = useParams<{ blocId: string }>();
  const [bloc, setBloc] = useState<Bloc | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentNotationIndex, setCurrentNotationIndex] = useState<number>(0);
  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [score, setScore] = useState<number>(0);
  const [gameFinished, setGameFinished] = useState<boolean>(false);
  const playerRef = useRef<HTMLDivElement | null>(null);

  // Define the allowed sy values
  const allowedSyValues = ['PD', 'PI', 'CD', 'CI'];

  const handleButtonPress = (sy: string) => {
    const currentNotation = bloc?.notationArray[currentNotationIndex];
    if (currentNotation?.sy === sy) {
      setResultMessage('Right');
      setScore(prevScore => prevScore + 1);
    } else {
      setResultMessage('Wrong');
    }

    setTimeout(() => {
      handleNext();
    }, 1000);
  };

  const resetGame = () => {
    setCurrentNotationIndex(0);
    setScore(0);
    setResultMessage(null);
    setGameFinished(false);
  };

  useEffect(() => {
    const fetchBloc = async () => {
      try {
        const blocRef = doc(db, 'blocs', blocId);
        const docSnap = await getDoc(blocRef);

        if (docSnap.exists()) {
          const blocData = docSnap.data() as Bloc;
          setBloc(blocData);
        } else {
          setError('Bloc not found');
        }
      } catch (err) {
        console.error('Error fetching bloc:', err);
        setError('Failed to fetch bloc');
      } finally {
        setLoading(false);
      }
    };

    fetchBloc();
  }, [blocId]);

  useEffect(() => {
    if (playerRef.current) {
      const player = new Player(playerRef.current);

      const checkTime = () => {
        const currentNotation = bloc?.notationArray[currentNotationIndex];
        const timeLimit = currentNotation?.time ? parseFloat(currentNotation.time) : 0;

        player.getCurrentTime().then(currentTime => {
          if (currentTime > timeLimit) {
            player.pause().then(() => {
              setTimeout(() => {
                const previousValidNotationIndex = findPreviousValidNotation(currentNotationIndex);
                if (previousValidNotationIndex !== null) {
                  const previousNotation = bloc?.notationArray[previousValidNotationIndex];
                  const iceTime = previousNotation?.time
                    ? parseFloat(previousNotation.time)
                    : 0.05;

                  setTimeout(() => {
                    player.setCurrentTime(iceTime).then(() => {
                      setTimeout(() => {
                        player.play();
                      }, 500); // Pause for 500ms at the beginning of the loop
                    });
                  }, 100); // Delay playback to avoid flicker
                } else {
                  setTimeout(() => {
                    player.setCurrentTime(0.05).then(() => {
                      setTimeout(() => {
                        player.play();
                      }, 500); // Pause for 500ms at the beginning of the loop
                    });
                  }, 100);
                }
              }, 1000); // Freeze for 1000 ms
            });
          }
        });
      };

      const interval = setInterval(checkTime, 100);
      return () => clearInterval(interval);
    }
  }, [bloc, currentNotationIndex]);

  const findPreviousValidNotation = (startIndex: number): number | null => {
    if (!bloc || !bloc.notationArray || startIndex <= 0) {
      return null;
    }

    for (let i = startIndex - 1; i >= 0; i--) {
      if (allowedSyValues.includes(bloc.notationArray[i].sy)) {
        return i;
      }
    }

    return null;
  };

  const handleNext = () => {
    if (bloc && bloc.notationArray) {
      let nextIndex = currentNotationIndex + 1;

      while (
        nextIndex < bloc.notationArray.length &&
        !allowedSyValues.includes(bloc.notationArray[nextIndex].sy)
      ) {
        nextIndex++;
      }

      if (nextIndex < bloc.notationArray.length) {
        setCurrentNotationIndex(nextIndex);
        setResultMessage(null);
      } else {
        setGameFinished(true);
      }
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error-message">Error: {error}</div>;

  if (gameFinished) {
    return (
      <div className="quiz-finished-banner">
        <h2>Quiz Completed!</h2>
        <p>Well done! Your final score is: <span className="score">{score}</span></p>
        <button onClick={resetGame} className="reset-quiz-button">
          Reset Quiz
        </button>
      </div>
    );
  }

  return (
    <div className="bloc-container">
      {bloc ? (
        <div className="bloc-content">
          <h1 className="bloc-title">{bloc.title}</h1>
          

          <div className="video-and-buttons">
            {/* Video container */}
            <div className="video-container">
              {bloc.videoUrl && (
                <div className="video-wrapper" ref={playerRef}>
                  <iframe
                    src={`https://player.vimeo.com/video/${bloc.videoUrl}?autoplay=1`}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title={bloc.title}
                  ></iframe>
                </div>
              )}
            </div>

            {/* Buttons and game banners container */}
            <div className="game-controls">
              {/* Score inside buttons container */}
              <div className="score-display">
                <h2>Score: {score}</h2>
              </div>

              <div className="notation-buttons">
  <button
    key="PD"
    onClick={() => handleButtonPress('PD')}
    className="notation-button"
    title="PD: Leader right, Follower left"
  >
    PD
  </button>

  <button
    key="PI"
    onClick={() => handleButtonPress('PI')}
    className="notation-button"
    title="PI: Leader left, Follower right"
  >
    PI
  </button>

  <button
    key="CD"
    onClick={() => handleButtonPress('CD')}
    className="notation-button"
    title="CD: Leader right, Follower right"
  >
    CD
  </button>

  <button
    key="CI"
    onClick={() => handleButtonPress('CI')}
    className="notation-button"
    title="CI: Leader left, Follower left"
  >
    CI
  </button>

</div>

              {/* Result message below buttons */}
              {resultMessage && (
                <div className="result-message">
                  <p>{resultMessage}</p>
                </div>
              )}
            </div>
          </div>

          {/* Game rules container */}
          <div className="game-rules">
  <h3 className="rules-title">Quiz Rules</h3>
  <p className="rules-intro">Ready to test your tango observation skills? Here's how to play:</p>
  <ol className="rules-list">
    <li>Watch the video and focus on the final position of each segment.</li>
    <li>Pay special attention to how the leader and follower position themselves in the last pose.</li>
    <li>Your task is to identify the correct combination of positions:</li>
    <ul className="position-list">
      <li><strong>PD:</strong> Leader right, Follower left.</li>
      <li><strong>PI:</strong> Leader left, Follower right.</li>
      <li><strong>CD:</strong> Leader right, Follower right.</li>
      <li><strong>CI:</strong> Leader left, Follower left.</li>
    </ul>
    <li>Make your selection based on what you see. Each correct answer boosts your score!</li>
    <li>If you finish the quiz, you'll see your final score and can restart the quiz to try again.</li>
  </ol>
  <p className="good-luck">Good luck, and enjoy the challenge!</p>
</div>
        </div>
      ) : (
        <div className="error-message">No bloc found</div>
      )}
    </div>
  );
};

export default GameComponent;
