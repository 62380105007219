import React from 'react';
import { Stack, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const NavLinks: React.FC = () => {
  const { user, isAdmin, signOut } = useAuth(); // Destructure signOut from useAuth

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      
      {!user ? (
        <>
          <Button color="inherit" component={RouterLink} to="/signin">
            Sign In
          </Button>
          <Button
            variant="contained"
            component={RouterLink}
            to="/signup"
            sx={{
              backgroundColor: '#FF7F50',
              color: 'white',
              '&:hover': {
                backgroundColor: '#FF6347',
              },
            }}
          >
            Sign Up
          </Button>
        </>
      ) : (
        <>
        
          
          {/* Add Profile Link */}
          <Button
            color="inherit"
            component={RouterLink}
            to="/profile" // Profile route
          >
            Profile
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF7F50',
              color: 'white',
              '&:hover': {
                backgroundColor: '#FF6347',
              },
            }}
            onClick={handleSignOut} // Call handleSignOut on click
          >
            Sign Out
          </Button>
        </>
      )}
    </Stack>
  );
};

export default NavLinks;
