import React, { useRef, useEffect } from 'react';
import { CardContent } from '@mui/material';
import DOMPurify from 'dompurify';
import { useDispatch } from 'react-redux';
import { BlocItemProps } from './models/Index';
import NotationList from './NotationList';
import ProsaDisplay from './ProsaDisplay';
import VideoPlayer from './VideoPlayer';
import Buttons from './Buttons';
import styles from './BlocItemStyles.module.css';
import Player from '@vimeo/player';
import { setCurrentProsa, setCurrentNotationIndex } from '../Store';
import SpeedControl from './SpeedControl'; // Import the SpeedControl component

// Import Google Analytics tracking functions
import ReactGA from 'react-ga4';

const BlocItem: React.FC<BlocItemProps> = ({ bloc, index, blocId }) => {
  const notationRefs = useRef<Array<HTMLDivElement | null>>([]);
  const dispatch = useDispatch();

  // Log pageview when the component mounts
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', title: `BlocItem: ${bloc.title}` });
  }, [bloc.title]);

  useEffect(() => {
    if (bloc.videoUrl && typeof window !== 'undefined') {
      const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
      const player = new Player(iframe);

      // Track video play event
      player.on('play', () => {
        ReactGA.event({
          category: 'Video',
          action: 'Play',
          label: `Video ID: ${blocId}`,
        });
      });

      // Time update for syncing notations
      player.on('timeupdate', (data: { seconds: number }) => {
        const currentTime = data.seconds;
        const notationIndex = bloc.notationArray.findIndex(
          (notation: any, i: number) =>
            parseFloat(notation.time) <= currentTime &&
            (i === bloc.notationArray.length - 1 ||
              currentTime < parseFloat(bloc.notationArray[i + 1].time))
        );

        if (notationIndex !== -1) {
          const currentNotation = bloc.notationArray[notationIndex];

          if (currentNotation.prosa2) {
            dispatch(setCurrentProsa(currentNotation.prosa2));
          }

          dispatch(setCurrentNotationIndex(notationIndex));

          // Scroll to the next notation if needed
          const secondIndex =
            notationIndex <= bloc.notationArray.length - 2 ? notationIndex + 1 : notationIndex;
          if (notationRefs.current[secondIndex]) {
            const boundingRect = notationRefs.current[secondIndex]?.getBoundingClientRect();

            if (
              boundingRect &&
              (boundingRect.left < 0 || boundingRect.right > window.innerWidth)
            ) {
              notationRefs.current[secondIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
              });
            }
          }
        }
      });
    }
  }, [bloc.videoUrl, bloc.notationArray, index, dispatch, blocId]);

  return (
    <CardContent>
      {bloc.description !== '' && (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bloc.description) }} />
      )}

      {/* Notation at the top */}
      <NotationList bloc={bloc} notationRefs={notationRefs} index={index} />

      {/* Container for Video and Prosa side by side */}
      <div className={styles.contentContainer}>
       
        <div className={styles.videoContainerWrapper}>
          <div>
            {bloc.videoUrl && (
              <VideoPlayer
                videoUrl={bloc.videoUrl}
                index={index}
              />
            )}
          </div>

          <div className={styles.speedControl}>
            <SpeedControl />
          </div>
        </div>
      
        <div className={styles.prosaContainer}>
          <ProsaDisplay />
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <Buttons blocId={blocId} />
      </div>
      <br />
      <div className={styles.toolsDescriptionContainer}>
        <h3>Master Your Tango Sequences with Powerful Tools</h3>
        <ul>
          <li>
            <strong>Video Player:</strong> Watch the sequence unfold as you practice or review. The player is fully interactive, allowing you to control playback speed and focus on specific movements.
          </li>
          <li>
            <strong>Pose List:</strong> Track your progress through the list of positions as they sync with the video in real-time. It's your visual map, helping you stay on top of every step.
          </li>
          <li>
            <strong>Detailed Descriptions:</strong> Get insightful breakdowns of technique, direction, and flow for each position in the sequence, helping you fine-tune every movement for flawless execution.
          </li>
          <li>
            <strong>Speed Control:</strong> Adjust the pace of the video to match your learning speed. Slow it down to understand the finer details, or speed it up to challenge your muscle memory.
          </li>
          <li>
            <strong>Interactive Notation:</strong> Pause the video anytime and click on the abbreviated notation above to jump directly to that position in the sequence. This lets you break down the movement, step by step.
          </li>
          <li>
            <strong>Responsive Controls:</strong> Use the interactive buttons to skip forward, focus on specific positions, or rewind without missing a beat, making practice more effective and enjoyable.
          </li>
        </ul>
      </div>
    </CardContent>
  );
};

export default BlocItem;
