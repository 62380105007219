// src/components/QuizPage.tsx

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Player from '@vimeo/player';
import { db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import DOMPurify from 'dompurify';

interface Notation {
  phrase: string;
  beat: string;
  innerBeat: string;
  sy: string;
  time: string;
  ln: string;
  la: string;
  lf: string;
  fn: string;
  fa: string;
  ff: string;
  prosa: string;
}

interface Bloc {
  id: string;
  description: string;
  videoUrl: string;
  notationArray: Notation[];
  aspectRatio?: any;
}

const QuizPage: React.FC = () => {
  const { courseId, lessonId, blocId } = useParams<{ courseId: string; lessonId: string; blocId: string }>();
  const [bloc, setBloc] = useState<Bloc | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [score, setScore] = useState<number>(0);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const playerRef = useRef<Player | null>(null);
  const navigate = useNavigate();
  const options = ['CD', 'CI', 'PD', 'PI'];

  useEffect(() => {
    const fetchBlocData = async () => {
      if (courseId && lessonId && blocId) {
        const blocRef = doc(db, `courses/${courseId}/lessons/${lessonId}/blocs/${blocId}`);
        const blocSnap = await getDoc(blocRef);
        if (blocSnap.exists()) {
          setBloc(blocSnap.data() as Bloc);
        }
      }
    };

    fetchBlocData();
  }, [courseId, lessonId, blocId]);

  useEffect(() => {
    const setupPlayer = async () => {
      if (bloc?.videoUrl && typeof window !== 'undefined') {
        const videoId = bloc.videoUrl ? Number(bloc.videoUrl) : undefined;

        setTimeout(() => {
          const player = new Player('vimeo-player', {
            id: videoId,
            width: 640,
          });
          playerRef.current = player;

          player.on('timeupdate', async (data: { seconds: number }) => {
            const currentTime = data.seconds;
            const startBeatTime = parseFloat(bloc.notationArray[currentQuestionIndex].time);
            const endBeatTime =
              currentQuestionIndex < bloc.notationArray.length - 1
                ? parseFloat(bloc.notationArray[currentQuestionIndex + 1].time)
                : await player.getDuration();

            if (currentTime >= endBeatTime) {
              player.setCurrentTime(startBeatTime);
            }
          });
        }, 0);
      }
    };

    setupPlayer();

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [bloc, currentQuestionIndex]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    if (bloc) {
      const correctOption = bloc.notationArray[currentQuestionIndex].sy;
      if (selectedOption === correctOption) {
        setScore((prevScore) => prevScore + 1);
      }

      if (currentQuestionIndex < bloc.notationArray.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setSelectedOption('');
      } else {
        setIsQuizFinished(true);
      }
    }
  };

  const handleBack = () => {
    navigate(`/course/${courseId}/lesson/${lessonId}`);
  };

  return (
    <Box>
      {bloc ? (
        <>
          {!isQuizFinished ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bloc.notationArray[currentQuestionIndex].prosa) }} />
              <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                {options.map((option, index) => (
                  <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                ))}
              </RadioGroup>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </>
          ) : (
            <Typography variant="h6" gutterBottom>
              Your score: {score} / {bloc.notationArray.length}
            </Typography>
          )}
          <Button variant="contained" color="secondary" onClick={handleBack}>
            Back to Lesson
          </Button>
          <Box className="video-container">
            <div id="vimeo-player" style={{ width: '100%', height: '0', paddingBottom: '56.25%', position: 'relative' }}>
              <iframe
                id="vimeo-player"
                src={`https://player.vimeo.com/video/${bloc?.videoUrl}`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
                allowFullScreen
                title="Vimeo video player"
              />
            </div>
          </Box>
        </>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Box>
  );
};

export default QuizPage;