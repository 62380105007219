import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import './ProfilePage.css'; // Assume you're adding some custom CSS here

// Extend the User interface to include your custom fields
interface ExtendedUser {
  uid: string;
  email: string;
  displayName: string;
  subscriptionStatus?: string;  // Include custom subscriptionStatus field
  stripeSubscriptionId?: string; // Include custom stripeSubscriptionId field
  token?: string; // Optional token field
}

const stripePromise = loadStripe('pk_live_Vf5cEc0MGcfQGeHkVkk6Yh2T'); // Your public Stripe key

const ProfilePage: React.FC = () => {
  // Use the extended user type
  const { user } = useAuth() as { user: ExtendedUser | null }; 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Determine the correct URL based on the environment
  const isProduction = process.env.NODE_ENV === 'production';
  const apiUrl = isProduction 
    ? 'https://stripe-firebase-server-807107572139.us-east1.run.app' 
    : 'http://localhost:8080';

  // Handle subscription cancellation
  const handleCancelSubscription = async () => {
    if (!user?.stripeSubscriptionId || !user.token) {
      console.log(user?.stripeSubscriptionId);
      console.log(user?.token);
      setError('No subscription or token found.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await axios.post(
        `${apiUrl}/api/cancel-subscription`, 
        { subscriptionId: user.stripeSubscriptionId, userId: user.uid },
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Correctly attach the token
          },
        }
      );
      alert('Subscription canceled successfully.');
    } catch (err: any) {
      console.error('Error canceling subscription:', err);
      setError('Failed to cancel subscription.');
    } finally {
      setLoading(false);
    }
  };

  // Handle new subscription
  const handleSubscribe = async () => {
    if (!user?.token || !user?.uid || !user?.email) {
      setError('No valid user data or token.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${apiUrl}/api/checkout`, 
        {
          priceId: 'TANGOVLOG_MONTHLY', // Your Stripe price ID
          userId: user.uid,
          email: user.email,
        }, 
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Include the token in the headers
          },
        }
      );

      const stripe = await stripePromise;
      const { error }: any = await stripe?.redirectToCheckout({
        sessionId: response.data.sessionId, // Use the session ID returned from the server
      });

      if (error) {
        console.error('Stripe checkout error', error);
        setError('Failed to redirect to Stripe checkout.');
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError('Failed to create checkout session.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-page">
      <h1>Welcome, {user?.displayName || 'User'}</h1>
      <div className="profile-info">
        <p><strong>Email:</strong> {user?.email}</p>
        <p><strong>Subscription Status:</strong> {user?.subscriptionStatus || 'None'}</p>
      </div>

      {/* Display subscription button based on status */}
      {user?.subscriptionStatus === 'active' ? (
        <button 
          className="button unsubscribe-button" 
          onClick={handleCancelSubscription}
          disabled={loading}
        >
          {loading ? 'Canceling...' : 'Cancel Subscription'}
        </button>
      ) : (
        <button 
          className="button subscribe-button" 
          onClick={handleSubscribe}
          disabled={loading}
        >
          {loading ? 'Subscribing...' : 'Subscribe Now'}
        </button>
      )}

      {/* Error display */}
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default ProfilePage;
