import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';

let theme: Theme = createTheme({
  palette: {
    primary: { main: '#009688' },
    secondary: { main: '#80cbc4' },
    background: { default: '#e0f2f1', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#757575' },
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 700, letterSpacing: '0.5px' },
    h2: { fontSize: '2.25rem', fontWeight: 700 },
    h3: { fontSize: '2rem', fontWeight: 700 },
    body1: { fontSize: '1rem', fontWeight: 400 },
    body2: { fontSize: '0.875rem', fontWeight: 400 },
    button: { textTransform: 'none', fontWeight: 500 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: 24, fontWeight: 'bold' },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: '0.3s',
          '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;