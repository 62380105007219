// src/components/UserFeedbackPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import FeedbackViewLive from './FeedbackViewLive';
import styles from './UserFeedbackPageStyles.module.css'; // Ensure the path matches your CSS module

const UserFeedbackPage: React.FC = () => {
  const { courseId, lessonId, blocId } = useParams<{ courseId?: string; lessonId?: string; blocId?: string }>();

  if (!courseId || !lessonId || !blocId) {
    return <div>Error: Missing required parameters.</div>;
  }

  return (
    <div className={styles.feedbackPageContainer}>
      <div className={styles.feedbackContainer}>
        <FeedbackViewLive  blocId={blocId} />
      </div>
    </div>
  );
};

export default UserFeedbackPage;