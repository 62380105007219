import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, FormGroup, Checkbox, Button, Typography } from '@mui/material';
import { db } from '../firebase-config';
import { setDoc, doc, collection, getDocs, query, where } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { useSelectedUser } from '../context/SelectedUserContext';

const feedbackItems: React.SetStateAction<FeedbackItem[]> = [
  // Your feedback items
];

interface FeedbackItem {
  title: string;
  active: boolean;
}

interface FeedbackPageProps {
  
  blocId: string;
  combinedBeat: string;
  currentVideoTime: number; // Ensure this prop is defined
  onSaveTime: (time: number) => void;
}

const FeedbackPageLive: React.FC<FeedbackPageProps> = ({  blocId, combinedBeat, currentVideoTime, onSaveTime }) => {
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackItem[]>(feedbackItems);
  const { user, isAdmin } = useAuth();
  const { selectedUser } = useSelectedUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/'); // Redirect to  if not admin
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    const fetchUserFeedback = async () => {
      if (selectedUser?.uid) {
        const feedbackRef = collection(db, 'feedback');
        const q = query(
          feedbackRef,
          where('blocId', '==', blocId),
          where('combinedBeat', '==', combinedBeat),
          where('userId', '==', user?.uid)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const feedbackDoc = querySnapshot.docs[0]; // Assuming there's only one matching document
          const userFeedback = feedbackDoc.data().feedback;
          setSelectedFeedback(userFeedback);
        } else {
          const feedbackDocRef = doc(
            db,
            'feedback',
            `${blocId}_${combinedBeat}_${selectedUser.uid}`
          );
          // Save the initial feedbackItems if no document exists
          await setDoc(feedbackDocRef, {
           
            blocId,
            combinedBeat,
            userId: selectedUser.uid,
            feedback: feedbackItems,
          });
          setSelectedFeedback(feedbackItems);
        }
      }
    };

    fetchUserFeedback();
  }, [ blocId, combinedBeat, selectedUser]);

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedFeedback(prevFeedback =>
      prevFeedback.map(item =>
        item.title === name ? { ...item, active: checked } : item
      )
    );
  };

  const saveFeedback = async () => {
    if (!selectedUser?.uid) {
      console.error("Missing required parameters to save feedback.");
      return;
    }

    const feedbackDoc = doc(db, 'feedback', `${blocId}_${combinedBeat}_${selectedUser.uid}`);

    await setDoc(feedbackDoc, {
      blocId,
      combinedBeat,
      userId: selectedUser.uid,
      feedback: selectedFeedback,
      time: currentVideoTime, // Save the video time
    });

    console.log('Feedback saved successfully');
  };

  return (
    <div>
      <h1>{combinedBeat}</h1>
      <FormGroup>
        {selectedFeedback.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={item.active}
                onChange={handleFeedbackChange}
                name={item.title}
              />
            }
            label={<><strong>{item.title}</strong></>}
          />
        ))}
      </FormGroup>
      <Button variant="contained" color="primary" onClick={saveFeedback}>
        Save Feedback
      </Button>
    
    
    </div>
  );
};

export default FeedbackPageLive;
