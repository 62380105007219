// src/Store/store.ts

import { configureStore } from '@reduxjs/toolkit';
import blocReducer from './blocSlice';
// Removed gameSlice reducer import
// import gameReducer from './gameSlice'; 

const store = configureStore({
  reducer: {
    bloc: blocReducer,
    // Removed gameSlice reducer from store
    // game: gameReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
