// blocSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bloc } from '../components/models/Index';

interface BlocState {
  startTime: any;
  endTime: any;
  blocs: Bloc[]; // Added blocs array to the state
  currentProsa: string;
  currentNotationIndex: number | null;
  mergingPoints: number[];
  playbackSpeed: number;
}

const initialState: BlocState = {
  blocs: [], // Initialize blocs array
  currentProsa: '',
  currentNotationIndex: null,
  mergingPoints: [],
  playbackSpeed: 1,
  startTime: '',
  endTime: ''
};

const blocSlice = createSlice({
  name: 'bloc',
  initialState,
  reducers: {
    setBlocs(state, action: PayloadAction<Bloc[]>) {
      state.blocs = action.payload;
    },
    setCurrentProsa(state, action: PayloadAction<string>) {
      state.currentProsa = action.payload;
    },
    setCurrentNotationIndex(state, action: PayloadAction<number | null>) {
      state.currentNotationIndex = action.payload;
    },
    setMergingPoints(state, action: PayloadAction<number[]>) {
      state.mergingPoints = action.payload;
    },
    setPlaybackSpeed(state, action: PayloadAction<number>) {
      state.playbackSpeed = action.payload;
    },
  },
});

export const {
  setBlocs,
  setCurrentProsa,
  setCurrentNotationIndex,
  setMergingPoints,
  setPlaybackSpeed,
} = blocSlice.actions;

export default blocSlice.reducer;
