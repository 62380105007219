import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration (replace with your actual config values)
const firebaseConfig = {
  apiKey: "AIzaSyD187zPJC0d-nvoMJaYRSaEmf4djxfTiC8",
  authDomain: "tangovideo-8ed7a.firebaseapp.com",
  databaseURL: "https://tangovideo-8ed7a.firebaseio.com",
  projectId: "tangovideo-8ed7a",
  storageBucket: "tangovideo-8ed7a.appspot.com",
  messagingSenderId: "807107572139",
  appId: "1:807107572139:web:e8b25755ce9289dc3722ee",
  measurementId: "G-02177KRZT9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { auth, db, functions, storage };