// ProsaDisplay.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { RootState } from '../Store/store';
import styles from './ProsaDisplayStyles.module.css';

const ProsaDisplay: React.FC = () => {
  const currentProsa = useSelector((state: RootState) => state.bloc.currentProsa);

  return (
    <div className={styles.prosaContainer} style={{ height: '100%' }}>
      {currentProsa && (
        <div className={styles.prosaContent}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentProsa) }} />
        </div>
      )}
    </div>
  );
};

export default ProsaDisplay;
