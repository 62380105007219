// FeedbackDetailsPage.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, getFirestore, collection, getDocs } from 'firebase/firestore';
import { Box, Typography, ThemeProvider } from '@mui/material';
import feedbackPageTheme from './FeedbackPageTheme';
import Player from '@vimeo/player';
import DOMPurify from 'dompurify';
import styles from './feedbackStyles.module.css';

interface Feedback {
  courseTitle: string;
  lessonId: string;
  courseId: string;
  text: string;
  videoUrl?: string;
  timestamp: any; // Adjust according to your timestamp format
  aspectRatio?: string;
}

interface Notation {
  phrase: string;
  beat: string;
  innerBeat: string;
  sy: string;
  time: string;
  ln: string;
  la: string;
  lf: string;
  fn: string;
  fa: string;
  ff: string;
  prosa: string;
  aspectRatio?: string;
}

interface Bloc {
  id: string;
  description: string;
  videoUrl: string;
  notationArray: Notation[];
}

const FeedbackDetailsPage: React.FC = () => {
  const { feedbackId, userId } = useParams<{ feedbackId: string; userId: string }>();
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [blocs, setBlocs] = useState<Bloc[]>([]);
  const db = getFirestore();
  const playerRef = useRef<Player | null>(null);

  useEffect(() => {
    const fetchFeedbackAndBlocs = async () => {
      if (userId && feedbackId) {
        const feedbackRef = doc(db, `users/${userId}/feedback/${feedbackId}`);
        const feedbackSnap = await getDoc(feedbackRef);
        if (feedbackSnap.exists()) {
          const fetchedFeedback = feedbackSnap.data() as Feedback;
          setFeedback(fetchedFeedback);

          // Fetching blocs from the lesson associated with the feedback
          const blocsRef = collection(db, `courses/${fetchedFeedback.courseId}/lessons/${fetchedFeedback.lessonId}/blocs`);
          const blocsSnap = await getDocs(blocsRef);
          const fetchedBlocs = blocsSnap.docs.map(doc => ({ id2: doc.id, ...doc.data() as Bloc }));
          setBlocs(fetchedBlocs);
        } else {
          console.log('No such feedback!');
        }
      }
    };

    fetchFeedbackAndBlocs();
  }, [userId, feedbackId, db]);

  useEffect(() => {
    if (feedback?.videoUrl && typeof window !== 'undefined') {
      setTimeout(() => {
        const player = new Player(`vimeo-player`, {
          url: `https://player.vimeo.com/video/${feedback.videoUrl}`,
          width: 640,
        });
        playerRef.current = player;
        return () => player?.destroy();
      }, 0);
    }
  }, [feedback?.videoUrl]);

  const handleNotationClick = (time: string) => {
    const timeInSeconds = parseFloat(time);
    if (playerRef.current) {
      playerRef.current.setCurrentTime(timeInSeconds).catch((error) => {
        console.error('Error setting video time:', error);
      });
    }
  };

  return (
    <ThemeProvider theme={feedbackPageTheme}>
      <Box sx={{ padding: '20px', bgcolor: 'background.default', color: 'text.primary' }}>
        {feedback ? (
          <>
            <Typography variant="h4">Feedback Details for {feedback.courseTitle}</Typography>
            {feedback.timestamp && (
              <Typography variant="body2">Feedback request time: {feedback.timestamp.toDate().toLocaleString()}</Typography>
            )}
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feedback.text) }} />
            
            {blocs.map((bloc, index) => (
              <Box key={index}>
               
                  <div className={styles.notationContainer}>
                  {bloc.notationArray.map((notation, index) => (
                    <div key={index} className={styles.notationUnit} 
                     onClick={() => handleNotationClick(notation.time)} 
                    >
                      <div className={styles.notationRow}>
                        <strong className={styles.notationItem}>{notation.beat}.{notation.innerBeat}</strong>
                      </div>
                      <div className={styles.notationRow}>
                        <div className={styles.notationItem}>{notation.sy}</div>
                       
                      </div>
                      <div className={styles.notationRow}>
                        <div className={styles.notationItem}>{notation.ln}{notation.la} {notation.lf} </div>
                     
                      </div>
                      <div className={styles.notationRow}>
                        <div className={styles.notationItem}>{notation.fn}{notation.fa} {notation.ff}</div>
        
                      </div>
                      <div className={styles.notationProsa}>
                        {notation.prosa}
                        </div>
                    </div>
                   
                  ))}
                </div>

              </Box>
            ))}
          </>
        ) : (
          <Typography>Loading feedback details...</Typography>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FeedbackDetailsPage;
