import React, { useRef, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import { Vector3 } from 'three';

const ARSwipeWalkthrough: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [tempGridPosition, setTempGridPosition] = useState<Vector3 | null>(null); // Temporary position
  const [gridPosition, setGridPosition] = useState<Vector3 | null>(null); // Permanent position

  // Request Camera Access
  useEffect(() => {
    const requestCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Camera access denied:", error);
      }
    };
    requestCameraAccess();
  }, []);

  // Handle click to set temporary grid position, only if permanent position is not set
  const handleScreenTap = (e: React.MouseEvent) => {
    if (gridPosition) return; // Ignore taps if gridPosition is confirmed

    const { clientX, clientY } = e;
    const position = new Vector3((clientX / window.innerWidth - 0.5) * 10, -1, -(clientY / window.innerHeight - 0.5) * 10);
    setTempGridPosition(position);
  };

  // Confirm grid position permanently
  const handleConfirmGridPosition = () => {
    if (tempGridPosition) {
      setGridPosition(tempGridPosition);
      setTempGridPosition(null); // Clear temporary position
    }
  };

  return (
    <div onClick={handleScreenTap} style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <video ref={videoRef} autoPlay playsInline style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }} />

      <Canvas style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />

        {/* Render grid at the confirmed position if set, otherwise at the temporary position */}
        {(gridPosition || tempGridPosition) && (
          <Plane position={(gridPosition || tempGridPosition)?.toArray()} args={[5, 5]} rotation={[-Math.PI / 2, 0, 0]}>
            <meshStandardMaterial color="blue" opacity={0.3} transparent />
          </Plane>
        )}
      </Canvas>

      {/* Button to confirm the grid position */}
      {tempGridPosition && !gridPosition && (
        <button
          onClick={handleConfirmGridPosition}
          style={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Set Grid Position
        </button>
      )}
    </div>
  );
};

export default ARSwipeWalkthrough;
