import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { Button, TextField, Typography, Container, Link } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import './SignUp.css';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();

  const signUp = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError(null);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { uid, email: userEmail } = userCredential.user;

      // Create user document in Firestore
      const userRef = doc(db, 'users', uid);
      await setDoc(userRef, { email: userEmail, allowed: false });

      navigate('/');
    } catch (error) {
      console.error(error);
      setError('Failed to create an account. Please try again.');
    }
  };

  const signUpWithGoogle = async () => {
    setError(null);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const { uid, email } = result.user;

      // Create or merge user document in Firestore
      const userRef = doc(db, 'users', uid);
      await setDoc(userRef, { email, allowed: false }, { merge: true });

      navigate('/');
    } catch (error) {
      console.error(error);
      setError('Failed to sign up with Google. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="signup-container">
        <Typography component="h1" variant="h4" className="signup-title">
          Join Us Today
        </Typography>
        <Typography component="p" variant="body1" className="signup-description">
          Start your tango journey with us. Sign up now to unlock exclusive sequences and lessons.
        </Typography>

        {/* Sign-Up Form */}
        <form onSubmit={signUp} noValidate className="signup-form">
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-input"
            InputLabelProps={{ style: { fontSize: '1.1rem' } }}
            InputProps={{ style: { fontSize: '1.1rem' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-input"
            InputLabelProps={{ style: { fontSize: '1.1rem' } }}
            InputProps={{ style: { fontSize: '1.1rem' } }}
          />

          {/* Error Message */}
          {error && (
            <Typography className="signup-error">
              {error}
            </Typography>
          )}

          {/* Submit button */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="signup-button signup-button-primary"
          >
            Create Account
          </Button>

          {/* Sign-Up with Google */}
          <Button
            onClick={signUpWithGoogle}
            fullWidth
            variant="contained"
            className="signup-button signup-button-google"
          >
            Sign Up with Google
          </Button>
        </form>

        {/* Redirect to Sign In */}
        <Typography variant="body2" className="signup-footer">
          Already have an account?{' '}
          <Link component={RouterLink} to="/signin" variant="body2" className="signup-footer-link">
            Sign In
          </Link>
        </Typography>
      </div>
    </Container>
  );
};

export default SignUp;
