import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../context/AuthContext';
import { Button, Typography, CircularProgress, Box } from '@mui/material';
import { getAuth } from 'firebase/auth'; // Firebase Auth import
import axios from 'axios'; // Using axios to make HTTP requests

const stripePromise = loadStripe('pk_live_Vf5cEc0MGcfQGeHkVkk6Yh2T'); // Your public Stripe key

const SubscribePage: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  console.log(stripePromise);

  // Detect if running in local or live environment
  const apiBaseUrl =
    window.location.hostname === 'localhost'
      ? 'https://stripe-firebase-server-807107572139.us-east1.run.app' // Local API server URL
      : 'https://stripe-firebase-server-807107572139.us-east1.run.app'; // Live API server URL

      console.log(window.location.hostname)

      const handleSubscribe = async () => {
        setLoading(true);
        try {
          const auth = getAuth();
          const token = await auth.currentUser?.getIdToken(); // Get Firebase Auth token
          const userId = auth.currentUser?.uid; // Get the Firestore user ID (UID)
          const email = auth.currentUser?.email; // Get the user email
      
          if (!token) {
            console.error('Firebase token is missing');
            setLoading(false);
            return;
          }
      
          if (!userId || !email) {
            console.error('User ID or email is missing');
            setLoading(false);
            return;
          }
      
          // Call your local or live API to create a Stripe Checkout Session
          const response = await axios.post(`${apiBaseUrl}/api/checkout`, {
            priceId: 'TANGOVLOG_MONTHLY', // Your Stripe price ID
            userId, // User's UID
            email, // User's email
          }, {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the headers
            },
          });
      
          const stripe = await stripePromise;
          const { error }: any = await stripe?.redirectToCheckout({
            sessionId: response.data.sessionId, // Use the session ID returned from the server
          });
      
          if (error) {
            console.error('Stripe checkout error', error);
          }
        } catch (error) {
          console.error('Error creating checkout session:', error);
        } finally {
          setLoading(false);
        }
      };

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '20px'
      }}
    >
      <Typography 
        variant="h4" 
        gutterBottom
        sx={{
          color: '#5D1049',
          marginBottom: '24px',
          textAlign: 'center'
        }}
      >
        Subscribe to Access All Content 
      </Typography>

      <Typography 
        variant="body1" 
        sx={{
          color: '#412039',
          marginBottom: '24px',
          textAlign: 'center',
          maxWidth: '600px'
        }}
      >
        Unlock exclusive content and gain full access to lessons, tutorials, and much more. Subscribe now to begin your Tango journey!
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Button 
          variant="contained" 
          onClick={handleSubscribe} 
          sx={{
            backgroundColor: '#5D1049',
            color: 'white',
            padding: '10px 20px',
            fontSize: '16px',
            '&:hover': {
              backgroundColor: '#412039'
            },
            marginBottom: '16px'
          }}
        >
          Subscribe Now
        </Button>
      )}
    </Box>
  );
};

export default SubscribePage;
