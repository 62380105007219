import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDoc, getFirestore, doc } from 'firebase/firestore';
import BlocItem from './BlocItem';
import { Card } from '@mui/material';
import { Bloc } from './models/Index';
import { styled } from '@mui/system';

// Use Record type for RouteParams
type RouteParams = Record<'courseId' | 'blocId', string | undefined>;

const StyledBox = styled('div')({
  padding: '0px',
});

const LessonDetailsPage: React.FC = () => {
  const { courseId, blocId } = useParams<RouteParams>();
  const [bloc, setBloc] = useState<Bloc | null>(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchBloc = async () => {
      if (blocId) {
        try {
          const blocRef = doc(db, 'blocs', blocId);
          const blocDoc = await getDoc(blocRef);

          if (blocDoc.exists()) {
            const blocData = { ...blocDoc.data(), id: blocDoc.id } as Bloc;
            setBloc(blocData);
          } else {
            console.error('No matching bloc found.');
          }
        } catch (error) {
          console.error('Error fetching bloc:', error);
        }
      }
    };

    fetchBloc();
  }, [blocId]);

  return (
    <StyledBox>
      <Card style={{ backgroundColor: '#009688' }}>
        {bloc && (
          <BlocItem
            key={bloc.id}
            bloc={bloc}
            index={0}
            courseId={courseId || ''}
            blocId={blocId || ''}
          />
        )}
      </Card>
    </StyledBox>
  );
};

export default LessonDetailsPage;
