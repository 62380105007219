import React, { useEffect, useState, useCallback } from 'react';
import { ThemeProvider, Grid, MenuItem, Select, InputLabel, FormControl, Button, Typography } from '@mui/material';
import { collection, query, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import theme from '../theme';
import { useAuth } from '../context/AuthContext';
import { Bloc } from './models/Index';
import BlocCard from './BlocCard';
import LargeBlocCard from './LargeBlocCard';
import debounce from 'lodash.debounce';

type FilterKeys = 'sy' | 'ln' | 'la' | 'fn' | 'fa';

const initialFilters: Record<FilterKeys, string> = {
  sy: '',
  ln: '',
  la: '',
  fn: '',
  fa: ''
};

const ITEMS_PER_PAGE = 9;

const BlocsListPage: React.FC = () => {
  const [blocs, setBlocs] = useState<Bloc[]>([]);
  const [filteredBlocs, setFilteredBlocs] = useState<Bloc[]>([]);
  const { user } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const [filters, setFilters] = useState(initialFilters);
  const navigate = useNavigate();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredBlocs.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const fetchBlocs = async () => {
      try {
        const blocsCollection = collection(db, 'blocs');
        const blocsQuery = query(blocsCollection, orderBy('timestamp', 'desc'), limit(24));
        const blocsSnapshot = await getDocs(blocsQuery);

        const blocsData = blocsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Bloc[];

        setBlocs(blocsData);
        setFilteredBlocs(blocsData);
      } catch (error) {
        console.error('Failed to fetch blocs:', error);
      }
    };

    const fetchSubscriptionStatus = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          setSubscriptionStatus(userData?.subscriptionStatus || 'none');
        } catch (error) {
          console.error('Failed to fetch subscription status:', error);
        }
      }
    };

    fetchBlocs();
    fetchSubscriptionStatus();
  }, [user]);

  const handleItemClick = (bloc: Bloc, index: number) => {
    // Get the current date and calculate the date 30 days ago
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
  
    // Convert the bloc timestamp to a Date object (assuming it's stored as a Firestore timestamp)
    const blocDate = new Date(bloc.timestamp.seconds * 1000); // Assuming 'bloc.timestamp' is a Firestore Timestamp
  
    // Check if the bloc is older than 30 days
    const isOlderThanThirtyDays = blocDate < thirtyDaysAgo;
  
    if (!user && index >= 3) {
      // Redirect unauthenticated users if they click beyond the first 3 items
      navigate('/signin');
    } else if (
      user &&
      subscriptionStatus !== 'active' &&
      (index >= 6 || isOlderThanThirtyDays)
    ) {
      // Redirect users to subscribe if:
      // - They click beyond index 6
      // - Or if the document is older than 30 days
      navigate('/subscribe');
    } else {
      // Proceed normally
      navigate(`/bloc/${bloc.id}`);
    }
  };
  

  const handleFilterChange = (key: FilterKeys, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    debouncedFilterBlocs({ ...filters, [key]: value });
  };

  const debouncedFilterBlocs = useCallback(
    debounce((newFilters) => {
      const filtered = blocs.filter(bloc =>
        bloc.notationArray.some((notation: any) =>
          (Object.keys(newFilters) as FilterKeys[]).every(key =>
            newFilters[key] === '' || notation[key] === newFilters[key]
          )
        )
      );
      setFilteredBlocs(filtered);
      setCurrentPage(1); // Reset to the first page when filters change
    }, 300),
    [blocs]
  );

  const handleResetFilters = () => {
    setFilters(initialFilters);
    setFilteredBlocs(blocs);
    setCurrentPage(1); // Reset to the first page
  };

  // Pagination controls
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  // Paginate filtered blocs
  const paginatedBlocs = filteredBlocs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginBottom: '16px' }}>
          Filter by Tango Action-Position
        </Typography>

        <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap', width: '100%' }}>
          {/* SY Field */}
          <FormControl variant="outlined" size="small" fullWidth style={{ flex: 1 }}>
            <InputLabel>SY</InputLabel>
            <Select
              label="SY"
              value={filters.sy}
              onChange={(e) => handleFilterChange('sy', e.target.value as string)}
            >
              <MenuItem value="">None</MenuItem>
              {[
                { value: 'PD', label: "PD - Leader's Right, Follower's Left" },
                { value: 'PI', label: "PI - Leader's Left, Follower's Right" },
                { value: 'CD', label: "CD - Both Right" },
                { value: 'CI', label: "CI - Both Left" }
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* LN Field */}
          <FormControl variant="outlined" size="small" fullWidth style={{ flex: 1 }}>
            <InputLabel>LN</InputLabel>
            <Select
              label="LN"
              value={filters.ln}
              onChange={(e) => handleFilterChange('ln', e.target.value as string)}
            >
              <MenuItem value="">None</MenuItem>
              {[
                { value: 'd', label: 'd - Leader Forward' },
                { value: 't', label: 't - Leader Backward' },
                { value: 'a', label: 'a - Leader Side' }
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* LA Field */}
          <FormControl variant="outlined" size="small" fullWidth style={{ flex: 1 }}>
            <InputLabel>LA</InputLabel>
            <Select
              label="LA"
              value={filters.la}
              onChange={(e) => handleFilterChange('la', e.target.value as string)}
            >
              <MenuItem value="">None</MenuItem>
              {[
                { value: 'b', label: 'b - Leader Boleo' },
                { value: 's', label: 's - Leader Sacada' },
                { value: 'i', label: 'i - Leader Inside' },
                { value: 'j', label: 'j - Leader Join Feet' }
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* FN Field */}
          <FormControl variant="outlined" size="small" fullWidth style={{ flex: 1 }}>
            <InputLabel>FN</InputLabel>
            <Select
              label="FN"
              value={filters.fn}
              onChange={(e) => handleFilterChange('fn', e.target.value as string)}
            >
              <MenuItem value="">None</MenuItem>
              {[
                { value: 'd', label: 'd - Follower Forward' },
                { value: 't', label: 't - Follower Backward' },
                { value: 'a', label: 'a - Follower Side' }
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* FA Field */}
          <FormControl variant="outlined" size="small" fullWidth style={{ flex: 1 }}>
            <InputLabel>FA</InputLabel>
            <Select
              label="FA"
              value={filters.fa}
              onChange={(e) => handleFilterChange('fa', e.target.value as string)}
            >
              <MenuItem value="">None</MenuItem>
              {[
                { value: 'b', label: 'b - Follower Boleo' },
                { value: 's', label: 's - Follower Sacada' },
                { value: 'i', label: 'i - Follower Inside' },
                { value: 'j', label: 'j - Follower Join Feet' }
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Button variant="outlined" color="primary" onClick={handleResetFilters} style={{ marginTop: '16px' }}>
          Reset Filters
        </Button>

        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <LargeBlocCard />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            {paginatedBlocs.map((bloc, index) => (
              <Grid item xs={12} sm={6} md={4} key={bloc.id}>
                <BlocCard bloc={bloc} index={index} onClick={() => handleItemClick(bloc, index)} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Pagination controls */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <Button
            variant="outlined"
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            style={{ marginRight: '8px' }}
          >
            Previous
          </Button>
          <Typography variant="body1" style={{ alignSelf: 'center' }}>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="outlined"
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            style={{ marginLeft: '8px' }}
          >
            Next
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default BlocsListPage;
