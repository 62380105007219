import React from 'react';
import { Box, List, ListItem, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { styled } from '@mui/system';

interface HamburgerMenuProps {
  onClose: () => void;
}

const StyledBox = styled(Box)({
  width: 250,
  backgroundColor: '#004D4D',
  height: '100%',
  padding: '16px',
});

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onClose }) => {
  const { user, isAdmin, signOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
      onClose();
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  const renderLinks = () => (
    <List>
      {!user ? (
        <>
          <ListItem button onClick={onClose}>
            <Button
              fullWidth
              component={RouterLink}
              to="/signin"
              sx={{
                backgroundColor: '#FF7F50',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#FF6347',
                },
              }}
            >
              Sign In
            </Button>
          </ListItem>
          <ListItem button onClick={onClose}>
            <Button
              fullWidth
              component={RouterLink}
              to="/signup"
              sx={{
                backgroundColor: '#004D40',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003d33',
                },
              }}
            >
              Sign Up
            </Button>
          </ListItem>
        </>
      ) : (
        <>
          {isAdmin && (
            <ListItem button onClick={onClose}>
              <Button
                fullWidth
                component={RouterLink}
                to="/admin"
                sx={{
                  backgroundColor: '#20B2AA',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#3CB371',
                  },
                }}
              >
                Admin
              </Button>
            </ListItem>
          )}
          <ListItem button onClick={onClose}>
            <Button
              fullWidth
              component={RouterLink}
              to="/profile"
              sx={{
                backgroundColor: '#4CAF50',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#388E3C',
                },
              }}
            >
              Profile
            </Button>
          </ListItem>
          <ListItem button onClick={handleSignOut}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#FF4500',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#FF6347',
                },
              }}
            >
              Sign Out
            </Button>
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <StyledBox role="presentation" onClick={onClose} onKeyDown={onClose}>
      {renderLinks()}
    </StyledBox>
  );
};

export default HamburgerMenu;
