import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config'; // Firestore config
import './ARSwipeWalkthrough.css';


interface ButtonsProps {
  blocId: string;
}

const Buttons: React.FC<ButtonsProps> = ({ blocId }) => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const [quizActive, setQuizActive] = useState<boolean>(false); // State to store quizActive status

  // Fetch the quizActive field from Firestore when the component loads
  useEffect(() => {
    const fetchQuizStatus = async () => {
      try {
        const blocRef = doc(db, 'blocs', blocId); // Reference to the document
        const blocDoc = await getDoc(blocRef); // Fetch the document
        if (blocDoc.exists()) {
          const data = blocDoc.data();
          setQuizActive(data?.quizActive || false); // Set quizActive status
        }
      } catch (error) {
        console.error('Error fetching quizActive field:', error);
      }
    };

    fetchQuizStatus();
  }, [blocId]);

  return (
    <div>
      {/* Conditionally render the button only if quizActive is true */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: quizActive ? '#FF7F50' : '#CCCCCC', // Disable look if quiz is inactive
          color: 'white',
          margin: '10px',
          '&:hover': {
            backgroundColor: quizActive ? '#FF6347' : '#CCCCCC',
          },
          textTransform: 'none',
          fontWeight: 'bold',
        }}
        onClick={() => navigate(`/game/${blocId}`)}
        disabled={!quizActive} // Disable the button if quiz is not active
      >
        Start Quiz
      </Button>

      {/* Admin-only button */}
      {isAdmin && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF7F50',
            color: 'white',
            margin: '10px',
            '&:hover': {
              backgroundColor: '#FF6347',
            },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
          onClick={() => navigate(`/admin-feedback/${blocId}`)}
        >
          View Admin Feedback
        </Button>
      )}

      {/* Button to navigate to the ARSwipeWalkthrough component */}
      {isAdmin && (
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#00796B', // Teal color for consistency
          color: 'white',
          margin: '10px',
          '&:hover': {
            backgroundColor: '#004D40',
          },
          textTransform: 'none',
          fontWeight: 'bold',
        }}
        onClick={() => navigate(`/ar-walkthrough/${blocId}`)}
      >
        Start AR Walkthrough
      </Button>
         )}
    </div>
  );
};

export default Buttons;