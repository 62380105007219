// SpeedControl.tsx

import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, setPlaybackSpeed } from '../Store'; // Adjust the import path as needed
import styles from './SpeedControlStyles.module.css'; // Import the CSS module for styling

const SpeedControl: React.FC = () => {
  const dispatch = useDispatch();
  const playbackSpeed = useSelector((state: RootState) => state.bloc.playbackSpeed);

  const availableSpeeds = [ 0.1, 0.25, 0.5, 0.75, 1];

  const handleSpeedChange = (speed: number) => {
    dispatch(setPlaybackSpeed(speed));
  };

  return (
    <div className={styles.speedControlContainer}>
      <ButtonGroup variant="contained" aria-label="Playback Speed">
        {availableSpeeds.map((speed) => (
          <Button
            key={speed}
            onClick={() => handleSpeedChange(speed)}
            className={`${styles.speedButton} ${
              playbackSpeed === speed ? styles.active : styles.inactive
            }`}
          >
            {speed}x
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default SpeedControl;
