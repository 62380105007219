import React, { useState } from 'react';
import { sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { Button, TextField, Typography, Container, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './SignIn.css';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();

  const checkOrCreateUserDoc = async (uid: string, email: string | null) => {
    try {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        await setDoc(userRef, { email, allowed: false });
      }
    } catch (err) {
      console.error("Error creating or checking user document:", err);
      throw new Error("Failed to check or create user document.");
    }
  };

  const signIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const { uid, email: userEmail } = userCredential.user;
      await checkOrCreateUserDoc(uid, userEmail);
      navigate('/');
    } catch (error) {
      setError('Failed to sign in. Please check your credentials.');
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    setError(null);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const { uid, email } = result.user;
      await checkOrCreateUserDoc(uid, email);
      navigate('/');
    } catch (error) {
      setError('Failed to sign in with Google. Please try again.');
      console.error(error);
    }
  };

  const handleForgotPassword = async () => {
    setError(null);
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset link sent. Please check your email.');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      console.error(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="signin-container">
        <Typography component="h1" variant="h4" className="signin-title">
          Welcome Back!
        </Typography>
        <form onSubmit={signIn} noValidate className="signin-form">
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signin-input"
            InputLabelProps={{ style: { fontSize: '1.1rem' } }}
            InputProps={{ style: { fontSize: '1.1rem' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signin-input"
            InputLabelProps={{ style: { fontSize: '1.1rem' } }}
            InputProps={{ style: { fontSize: '1.1rem' } }}
          />
          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPassword}
            className="signin-link"
          >
            Forgot Password?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="signin-button signin-button-primary"
          >
            Sign In
          </Button>
          <Button
            onClick={signInWithGoogle}
            fullWidth
            variant="contained"
            className="signin-button signin-button-google"
          >
            Sign in with Google
          </Button>
        </form>
        {error && (
          <Typography className="signin-error">
            {error}
          </Typography>
        )}
      </div>
      <Typography variant="body2" className="signin-footer">
        Don't have an account?{' '}
        <Link component={RouterLink} to="/signup" variant="body2" className="signin-footer-link">
          Sign Up
        </Link>
      </Typography>
    </Container>
  );
};

export default SignIn;
