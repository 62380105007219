import React, { useEffect, useState } from 'react';
import { List, ListItem, Typography, Box } from '@mui/material';
import { db } from '../firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import styles from './feedbackViewLive.module.css';
import { useAuth } from '../context/AuthContext';

interface Feedback {
  id: string;
  userId: string;
  combinedBeat: string;
  feedback: { role: string; title: string; description: string; active: boolean }[];
}

interface FeedbackViewProps {
  blocId: string;
}

const FeedbackViewLive: React.FC<FeedbackViewProps> = ({ blocId }) => {
  const [feedbackList, setFeedbackList] = useState<Feedback[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchFeedback = async () => {
      if (blocId && user) {
        const feedbackRef = collection(db, 'feedback');
        const q = query(
          feedbackRef,
          where('blocId', '==', blocId),
          where('userId', '==', user.uid)
        );
        const feedbackSnap = await getDocs(q);
        const feedbackData = feedbackSnap.docs.map(doc => ({ ...doc.data(), id: doc.id } as Feedback));
        setFeedbackList(feedbackData);
      }
    };

    fetchFeedback();
  }, [blocId, user]);

  return (
    <Box className={styles.container}>
      <Typography variant="h6" className={styles.header}>
        Your Personalized Feedback
      </Typography>
      <List className={styles.list}>
        {feedbackList.map((feedback, i) => (
          <ListItem key={i} className={styles.listItem}>
            <Box sx={{ marginBottom: '20px' }}>
              {feedback.feedback &&
                feedback.feedback
                  .filter(x => x.active === true)
                  .map((item, index) => (
                    
                    <Box key={index} className={styles.feedbackItem} sx={{ padding: '15px', backgroundColor: '#2C3E50', borderRadius: '12px', marginBottom: '15px' }}>
                      <Typography variant="h6" className={styles.beatText} sx={{ color: '#E74C3C', fontWeight: 'bold', marginBottom: '8px' }}>
                        {index === 0 ? `Beat: ${feedback.combinedBeat}` : null}
                      </Typography>
                      <Typography variant="body1" className={styles.feedbackText} sx={{ color: '#ECF0F1' }}>
                        <strong>{item.role} {item.title}</strong>: {item.description}
                      </Typography>
                    </Box>
                  ))}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FeedbackViewLive;
