import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { onAuthStateChanged, User, signOut as firebaseSignOut } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';

interface ExtendedUser extends User {
  subscriptionStatus?: string;
  stripeSubscriptionId?: string;
  token?: string;
}

interface AuthContextType {
  user: ExtendedUser | null;
  isAdmin: boolean;
  isSubscribed: boolean;
  selectedUser: ExtendedUser | null;
  setSelectedUser: (user: ExtendedUser | null) => void;
  signOut: () => Promise<void>;
  token: string | null;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAdmin: false,
  isSubscribed: false,
  selectedUser: null,
  setSelectedUser: () => {},
  signOut: async () => {},
  token: null,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<ExtendedUser | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch the user's token for API requests
        const fetchedToken = await user.getIdToken();
        setToken(fetchedToken);

        // Fetch Firestore data for the user
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : null;

        // Merge Firebase Auth data with Firestore data
        const extendedUser: ExtendedUser = {
          ...user,
          token: fetchedToken,
          subscriptionStatus: userData?.subscriptionStatus,
          stripeSubscriptionId: userData?.stripeSubscriptionId,
        };

        // Set user and state
        setCurrentUser(extendedUser);
        setIsSubscribed(userData?.subscriptionStatus === 'active');
        setIsAdmin(user.uid === "8dy0t60qvKZlTGfe8LkNYpFXkRq2");
        setSelectedUser(extendedUser);
      } else {
        // Reset state when user logs out
        setCurrentUser(null);
        setIsAdmin(false);
        setIsSubscribed(false);
        setSelectedUser(null);
        setToken(null);
      }
    });

    return unsubscribe; // Clean up the listener on unmount
  }, []);

  const signOut = async () => {
    await firebaseSignOut(auth);
    setCurrentUser(null);
    setIsAdmin(false);
    setIsSubscribed(false);
    setSelectedUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ user: currentUser, isAdmin, isSubscribed, selectedUser, setSelectedUser, signOut, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
