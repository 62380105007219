import React from 'react';
import { Card, Typography, Button } from '@mui/material';

const LargeBlocCard: React.FC = () => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 77, 77, 0.01)', // Dark teal with 50% opacity for transparency
        color: 'white',
        // padding: 3,
        // boxShadow: 3,
        // '&:hover': { boxShadow: 6 },
      }}
    >
    
    
    </Card>
  );
};

export default LargeBlocCard;
