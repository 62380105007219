import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SelectedUser {
  uid: string;
  email: string;
  displayName: string;
}

interface SelectedUserContextType {
  selectedUser: SelectedUser | null;
  setSelectedUser: (user: SelectedUser | null) => void;
  clearSelectedUser: () => void;
}

const SelectedUserContext = createContext<SelectedUserContextType | undefined>(undefined);

export const SelectedUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);

  const clearSelectedUser = () => {
    setSelectedUser(null);
  };

  return (
    <SelectedUserContext.Provider value={{ selectedUser, setSelectedUser, clearSelectedUser }}>
      {children}
    </SelectedUserContext.Provider>
  );
};

export const useSelectedUser = (): SelectedUserContextType => {
  const context = useContext(SelectedUserContext);
  if (context === undefined) {
    throw new Error('useSelectedUser must be used within a SelectedUserProvider');
  }
  return context;
};
