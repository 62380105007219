import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let feedbackPageTheme = createTheme({
  palette: {
    primary: {
      // Using #009688 for the main primary color
      main: '#009688',
      // Adding shades for contrast adjustments if necessary
      light: '#b2dfdb',
      dark: '#00796b',
      contrastText: '#ffffff', // Keeping white text for good contrast
    },
    secondary: {
      // Choosing a shade for the secondary color, can adjust based on preference
      main: '#80cbc4',
      light: '#e0f2f1',
      dark: '#4db6ac',
      contrastText: '#e0f2f1'
      // contrastText can be adjusted if secondary colors are used for backgrounds
    },
    background: {
      default: '#004d40', // A dark green shade for the general background
      paper: '#ffffff', // Keeping paper as white for contrast
    },
    text: {
      primary: '#ffffff', // White text for primary content
      secondary: '#757575', // A lighter shade for secondary text
    },
  },
  typography: {
    fontFamily: '"Lato", sans-serif', // Example font family
    // You can add more typography settings here
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24, // Rounded buttons for a modern look
          fontWeight: 'bold', // Bold font weight for buttons
          // Add more button styles as needed
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Adding a subtle shadow
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // More prominent shadow on hover
          },
          // More card styles can be added here
        },
      },
    },
    // Add overrides for other MUI components as needed
  },
});

feedbackPageTheme = responsiveFontSizes(feedbackPageTheme);

export default feedbackPageTheme;
